<template>
  <div>
    <c-tab
      type="horizon"
      :dense="true"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :height="tabHeight"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :popupParam="popupParam"
          :check.sync="check"
          :select.sync="select"
          :isOld="isOld"
          :disabled="disabled"
          :contentHeight.sync="contentHeight"
          :imprTabInfo.sync="imprTabInfo"
          @getDetail="getDetail"
          @closePopup="closePopup"
        />
      </template>
    </c-tab>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'fireFightingCheckDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopFireFightingCheckId: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      tab: 'info',
      check: {
        sopFireFightingCheckId: '',  // 소방설비 점검 일련번호
        plantCd: '',  // 사업장
        sopFireFightingCheckTypeCd: null,  // 점검유형
        checkName: '',  // 점검명
        sopFireFightingCheckStepCd: '',  // 점검진행상태
        checkStartDate: '',  // 점검시작일
        checkEndDate: '',  // 점검종료일
        period: [],
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        targets: [],
        deleteTargets: [],
        imprs: [],
      },
      select: {
        sopFireFightingId: '',
        sopFireFightingKindCd: '',
        fireFightingName: '',
        editFlag: '',
        results: [],
      },
      editable: '',
      detailUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.sopFireFightingCheckId)
    },
    disabled() {
      return this.check.sopFireFightingCheckStepCd === 'FFC0000005'
    },
    tabHeight() {
      return String(this.contentHeight - 36);
    },
    tabItems() {
      let _items = [
        { name: 'info', icon: 'info', label: '점검상세', component: () => import(`${'./fireFightingCheckInfo.vue'}`) },
      ]
      if (this.isOld) {
        _items.push({
          name: 'impr', icon: 'construction', label: '개선관리', component: () => import(`${'@/pages/common/ibm/imprTab.vue'}`)
        })
      }
      return _items;
    },
    imprTabInfo() {
      return {
        key: this.popupParam.sopFireFightingCheckId, // 
        ibmTaskTypeCd: 'ITT0000180',
        ibmTaskUnderTypeCd: 'ITTU000205',
        requestContents: this.check.checkName,
        disabled: this.disabled,
        isOld: true,
        isTable: true,
        gridItem: {
          listUrl: '',
          param: null,
          title: '점검항목별 개선목록',
          merge: [
            { index: 0, colName: 'sopFireFightingKindName' },
            { index: 1, colName: 'sopFireFightingCheckTargetResultId' },
            { index: 2, colName: 'sopFireFightingCheckTargetResultId' },
            { index: 3, colName: 'sopFireFightingCheckTargetResultId' },
          ],
          data: this.check.imprs,
          columns: [
            {
              name: 'sopFireFightingKindName',
              field: 'sopFireFightingKindName',
              label: '소방설비',
              align: 'center',
              style: 'width: 150px',
              sortable: false,
            },
            {
              name: 'itemName',
              field: 'itemName',
              label: '점검항목',
              align: 'left',
              style: 'width: 400px',
              sortable: false,
            },
            {
              name: 'sopFireFightingCheckResultName',
              field: 'sopFireFightingCheckResultName',
              label: '점검결과',
              align: 'center',
              style: 'width: 100px',
              sortable: false,
            },
            {
              name: 'remark',
              field: 'remark',
              label: 'LBLREMARK',
              align: 'center',
              style: 'width: 150px',
              sortable: false,
            },
          ],
          research: {},
        },
        gridOther: {
          //항목외 개선 목록
          title: 'LBL0010147',
        },
      }
    }
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.fft.fireFightingCheck.get.url
      // code setting
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopFireFightingCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.check, _result.data)
          this.$set(this.check, 'period', [this.check.checkStartDate, this.check.checkEndDate])
          if (this.check.targets && this.check.targets.length > 0 && !this.select.sopFireFightingId) {
            this.select = {
              sopFireFightingId: this.check.targets[0].sopFireFightingId,
              sopFireFightingKindCd: this.check.targets[0].sopFireFightingKindCd,
              fireFightingName: this.check.targets[0].fireFightingName,
              editFlag: '',
              results: this.check.targets[0].results,
            }
          }
        },);
      } else {
        // 
        this.$set(this.check, 'sopFireFightingCheckStepCd', 'FFC0000001')
      }
    },
    closePopup() {
      this.$emit('closePopup')
    }
  }
};
</script>